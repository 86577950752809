import { css } from 'styled-components';
import { mediaBreaks } from 'devices';

const timingFunctions = {
	standard: 'cubic-bezier(0.4, 0, 0.2, 1)',
	decelerate: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
	accelerate: 'cubic-bezier(0.4, 0.0, 1, 1)'
};

const theme = {
	colors: {
		black: '#2F2F2F',
		blackHover: '#585858',
		blackPressed: '#16232D',
		blue: '#2979FF',
		blueDisabled: '#93BBFF',
		blueHover: '#5393FF',
		bluePressed: '#1759FF',
		blueAvailable: '#E4ECFA',
		darkGrey: '#939598',
		darkGreyHover: '#A8AAAC',
		darkGreyPressed: '#747679',
		fizzGreen: '#1DB779',
		fizzGreenHover: '#4AC593',
		fizzGreenPressed: '#109D59',
		green: '#74C655',
		greenHover: '#8FD177',
		greenPressed: '#54B039',
		grey: '#D5D7DB',
		greyHover: '#DDDFE2',
		greyHoverLight: '#EAEBED',
		greyPressed: '#C4C6CC',
		lightBlue: '#08C4C4',
		lightBlueHover: '#39CFCF',
		lightBluePressed: '#04ADAD',
		lightGrey: '#E8EAF6',
		lightGreyHover: '#F4F5FB',
		lightGreyPressed: '#D0D3E3',
		lightGreySelected: '#E4ECFA',
		lightTurquoise: '#BBE9D6',
		orange: '#FF8D10',
		orangeLight: '#FFEAD3',
		orangeHover: '#FFA33F',
		orangePressed: '#FF6E08',
		red: '#F13B70',
		redHover: '#F3628C',
		redPressed: '#EB2450',
		redUnavailable: '#FFD9D9',
		skyBlue: '#02BFFB',
		skyBluePressed: '#00A6FA',
		statusRed: '#FF4343',
		statusRedHover: '#FF6868',
		statusRedPressed: '#FF2A2A',
		transparentWhite: 'rgba(256, 256, 256, 0.5)',
		violet: '#BB98F1',
		violetHover: '#A06CEC',
		violetPressed: '#8848E7',
		white: '#FFF',
		yellow: '#FFCE17',
		yellowHover: '#FFD745',
		yellowPressed: '#FFBA0C'
	},
	font: {
		family: `
		'Roboto',
		sans-serif
		`,
		base: '13px',
		baseSmall: '12px',
		medium: '14px',
		small: '11px',
		xsmall: '9px',
		large: '16px',
		xlarge: '18px',
		xxlarge: '20px'
	},
	transition: `all 250ms ${timingFunctions.standard}`,
	timingFunctions,
	mixins: {
		flexCenter: css`
			display: flex;
			align-items: center;
			justify-content: center;
		`,
		placeholder(styles) {
			return css`
				&::-moz-placeholder {
					${styles}
				}
				&::-webkit-input-placeholder {
					${styles}
				}
				&:-moz-placeholder {
					${styles}
				}
				&:-ms-input-placeholder {
					${styles}
				}
				&::placeholder {
					${styles}
				}
			`;
		},
		transition(property = 'all', time = '200ms') {
			let transitionProperty;
			if (property.includes(',')) transitionProperty = `transition-property: ${property}`;

			const transition = `${property.split(',')[0]} ${time} ${timingFunctions.standard}`;

			return css`
				transition: ${transition};
				${transitionProperty};
			`;
		},
		scrollbar(thumbColor, shadowColor, hide = false) {
			const { colors } = theme;
			return css`
				&::-webkit-scrollbar {
					width: 10px;
					display: ${hide && 'none'};
				}
				&::-webkit-scrollbar-track {
					box-shadow: inset 0 0 6px ${shadowColor || colors.white};
					display: ${hide && 'none'};
				}
				&::-webkit-scrollbar-thumb {
					height: 5px;
					width: 4px;
					border-radius: 50px;
					background-color: ${thumbColor};
					display: ${hide && 'none'};
				}
			`;
		}
	}
};

export default theme;

export const { colors, icon, font, transition, mixins } = theme;

/** Search for provided color (by name) in theme and returns its hex code.
 * If not found, returns the input (either name or hex code).
 * @param {string} color (name or hex code)
 */
export const findColorInTheme = color => theme.colors[color] || color;

export { timingFunctions };

/** Hide element on printable version */
export const hideOnPrinter = css`
	${mediaBreaks.onlyPrint`
		display: none;
	`}
`;
