import { createSelector } from 'reselect';
import { pick } from 'lodash';
import { getCurrentProps, getLabelValue, createDeepEqualSelector } from 'utils/selectors';
import { getMappedFieldValue } from 'utils/mappers';
import { translateValueComponent, makeTemplateValue } from 'utils/translateComponent';

export const getFilters = state => state.filters;

export const getAppliedFilters = state => state.appliedFilters;
export const getDefaultValues = state => state.defaultValues;

export const hasRemoteFilters = createSelector(
	getFilters,
	filters => filters.some(filter => filter.remote)
);

export const hasFiltersApplied = createSelector(
	getAppliedFilters,
	appliedFilters => Object.keys(appliedFilters).length > 0
);

const getField = props => props.field;
const getValue = props => props.value;
const getRowData = props => props.rowData;
// Function for get value translated for fields in table
export const getFieldValue = () =>
	createSelector(
		[getField, getValue, getRowData],
		(field, value, rowData) => {
			const { mapper, name } = field;

			return mapper
				? getMappedFieldValue({
						fieldName: name,
						mapper,
						value,
						currentData: rowData,
						translateValueComponent,
						makeTemplateValue
				  })
				: value;
		}
	);

/**
 * Transform fields to data valid for header fields in browse table
 * @param {object} statePage
 * @param {object} props
 */
const mapHeaderField = (
	hasMassiveActions,
	massiveActionsAreReady,
	schema,
	showMassiveActionsCheckboxes
) => {
	const { sortEndpoint } = schema;

	const headerFields = schema.fields.reduce((accum, field) => {
		const accumulator = [...accum];
		const { deviceDisplay = 'desktop' } = field;

		if (deviceDisplay !== 'mobile') accumulator.push({ ...field });

		return accumulator;
	}, []);

	const headerColumns = headerFields.map(field => ({
		name: field.name,
		component: field.component,
		columnSortableMatch: field.columnSortableMatch,
		text: getLabelValue(field),
		showOnPreview: field.showOnPreview,
		sortable: field.attributes && !!field.attributes.sortable,
		initialSortDirection:
			field.attributes && field.attributes.initialSortDirection
				? field.attributes.initialSortDirection
				: 'desc'
	}));

	const sortEndpointColumn = sortEndpoint && {
		name: 'draggable',
		text: ' ',
		initialSortDirection: 'desc',
		sortable: false,
		draggable: true
	};

	const massiveActionsColumn = hasMassiveActions &&
		showMassiveActionsCheckboxes &&
		massiveActionsAreReady && {
			name: 'massiveActions',
			massiveActionsCell: true,
			showOnPreview: true
		};

	return [sortEndpointColumn, massiveActionsColumn, ...headerColumns].filter(Boolean);
};

const getBrowseData = (state, props) => state.browses[props.keyBrowse];

// Function for get label value for header fields in browsetable
export const getHeaderFields = createSelector(
	[getBrowseData],
	({ hasMassiveActions, massiveActionsAreReady, schema, showMassiveActionsCheckboxes }) =>
		mapHeaderField(hasMassiveActions, massiveActionsAreReady, schema, showMassiveActionsCheckboxes)
);

// Function for get label value for browse filters (input|select)
export const getFilterLabel = () =>
	createSelector(
		[getCurrentProps],
		props => getLabelValue(props)
	);

const getSchemaProps = (state, { schema }) => {
	const keys = [
		'canCreate',
		'canEdit',
		'canPreview',
		'canRefresh',
		'canView',
		'fields',
		'source',
		'sortEndpoint',
		'appearance',
		'graphs',
		'root',
		'rowLink',
		'massiveActions',
		'rowCollapse',
		'statusBar',
		'featureFlags'
	];

	return pick(schema, keys);
};

const getStateProps = (state, props) => {
	const keys = [
		'isEmptyBrowse',
		'applyFiltersToggle',
		'appliedFilters',
		'hasMassiveActions',
		'massiveActionsAreReady',
		'massiveActionsAreFetching',
		'showMassiveActionsCheckboxes',
		'allRowsSelected',
		'selectedRows',
		'isReady',
		'sortBy',
		'sortDirection',
		'preview',
		'total',
		'rows',
		'isFetching',
		'error',
		'errorData'
	];

	return pick(state.browses[props.keyBrowse], keys);
};
const getPreview = (state, keyBrowse) => state.browses[keyBrowse].preview;
const getPreviewState = state => state.preview;

export const getBrowseSchemaProps = () =>
	createDeepEqualSelector([getSchemaProps], schemaProps => schemaProps);

export const getBrowseStateProps = () =>
	createDeepEqualSelector([getStateProps], stateProps => stateProps);

export const getRows = () =>
	createDeepEqualSelector([(state, props) => state.browses[props.keyBrowse].rows], rows => rows);

export const getCurrentPreviewProps = () =>
	createDeepEqualSelector([getPreview, getPreviewState], (preview, previewState) => ({
		...previewState[preview.currentPreview],
		name: preview.currentPreview
	}));

export const getCurrentRowCollapsePreviewProps = () =>
	createDeepEqualSelector([getPreview, getPreviewState], (preview, previewState) => {
		const { currentRowCollapsePreview } = preview;

		return currentRowCollapsePreview
			? { ...previewState[currentRowCollapsePreview], name: currentRowCollapsePreview }
			: {};
	});
