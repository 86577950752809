import { stringify } from 'qs';
import { getUrlWithEndpointParameters } from 'utils';
import { getPathFiltersParameters, getQueryFiltersParameters } from 'utils/request';

/**
 * Helper for make an url from a schema source
 * @param {object} source
 * @param {string} id
 */
export const createURLFromSource = (source, id) => {
	const { method, service, namespace } = source;
	const idSuffix = method === 'edit' ? `/${id}` : '';

	return `/${service}/${namespace}/${method}${idSuffix}`;
};

/**
 * Helper for add an filters to URL filters with endpointParameters
 * @param {Object} endpointParameters
 * @param {path} string
 
 */
export const addQueryFiltersToURl = (endpointParameters, sourcePath, data = {}) => {
	let path = sourcePath;
	const queries = getQueryFiltersParameters(endpointParameters, data);

	if (queries) {
		const linkHasQuery = sourcePath.includes('?');
		path = `${sourcePath}${linkHasQuery ? '&' : '?'}${stringify(queries)}`;
	}
	return path;
};

/**
 * Helper for make an URL with filters and paths from endpointParameters and source
 * @param {array} endpointParameters
 * @param {object} source
 * @param {object} data
 */
export const createURLWithEndPointParametersAndSource = (endpointParameters, source, id) => {
	if (!source) return '';

	const sourcePath = createURLFromSource(source, id);

	if (!endpointParameters) return sourcePath;

	const pathParameters = getPathFiltersParameters(endpointParameters);
	const pathWithReplacedId =
		pathParameters && 'id' in pathParameters ? sourcePath.replace(id, '{id}') : sourcePath;
	const redirectURL = getUrlWithEndpointParameters(pathWithReplacedId, pathParameters);

	const decodeURL = decodeURIComponent(addQueryFiltersToURl(endpointParameters, redirectURL));

	return decodeURL;
};
