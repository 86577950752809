import * as types from './types';

const initialState = {
	schema: {},
	isReady: false,
	isFetching: true,
	isModalOpen: false,
	hasErrorSchema: false,
	hasErrorJanisClient: false,
	janisClient: '',
	errorData: {},
	remoteActionsCache: {}
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.CHANGE_PAGE:
			return {
				...state,
				isReady: false,
				schema: {},
				remoteActionsCache: {}
			};

		case types.FETCH_SCHEMA_REQUEST:
			return {
				...state,
				isFetching: true,
				isReady: false,
				hasErrorSchema: false
			};

		case types.FETCH_SCHEMA_SUCCESS:
			return {
				...state,
				schema: action.schema,
				isFetching: false,
				isReady: true,
				hasErrorSchema: false,
				previewSchema: {}
			};

		case types.FETCH_SCHEMA_FAILURE:
			return {
				...state,
				isFetching: false,
				hasErrorSchema: true,
				errorData: { ...action.status },
				previewSchema: {}
			};

		case types.FETCH_JANIS_CLIENT_SUCCESS:
			return {
				...state,
				janisClient: action.janisClient,
				hasErrorJanisClient: false
			};

		case types.FETCH_JANIS_CLIENT_FAILURE:
			return {
				...state,
				hasErrorJanisClient: true,
				errorData: { ...action.status }
			};

		case types.SET_REMOTE_ACTIONS_CACHE:
			return {
				...state,
				remoteActionsCache: action.data
			};

		case types.TOGGLE_MODAL_STATUS:
			return {
				...state,
				isModalOpen: action.status
			};

		default:
			return state;
	}
}
