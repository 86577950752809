/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { optionShape } from 'utils/prop-types';
import translationHOC from 'hocs/translationHOC';
import UseDevicesWrapper from 'components/UseDevicesWrapper';
import AsyncSelect from './AsyncSelect';
import LocalSelect from './LocalSelect.js';
import { parseOptions } from './utils';
import styles from './styles';
import customizedSelectComponents from './components';

const Select = ({
	defaultValue,
	defaultFilter,
	disabled,
	translateHelperString,
	noOptionsMessage,
	t,
	translateGroupLabel,
	isDatePicker,
	identifier,
	width,
	iconColor,
	name,
	selectRef,
	showImage,
	...props
}) => {
	const ref = useRef();

	const [state, setState] = useState({
		value: defaultValue,
		width,
		showValueImage: !!showImage,
		creatableOptions: []
	});

	// Method for set select width
	const setSelectWidth = () => {
		if (!ref.current) return;

		return setState(prevState => ({
			...prevState,
			width: Number.isNaN(Number(width)) ? ref.current.clientWidth : width
		}));
	};

	const handleChange = currentValue => {
		if (!state.width) setSelectWidth();

		let value = currentValue;

		// If select is MultiSelect and value is falsie
		const { isMulti } = props;
		if (isMulti && !value) value = [];

		setState(prevState => ({ ...prevState, value }));
		props.onChange(value);
	};

	const handleCreateOption = (prevValue = [], value) => {
		const { creatableOptions: stateCreatableOptions } = state;
		const { isMulti, addCreatedOptions } = props;

		const valueFormatted = { value, label: value };

		const creatableOptions = addCreatedOptions
			? [...stateCreatableOptions, valueFormatted]
			: [...stateCreatableOptions];

		setState(prevState => ({ ...prevState, creatableOptions }));
		handleChange(isMulti ? [...(prevValue || []), valueFormatted] : valueFormatted);
	};

	// Set creatable options when have value and options is empty
	const setInitialCreatableOptions = () => {
		const { value: stateValue } = state;
		const { value = null, canCreate, options } = props;

		const currentValue = 'value' in props ? value : stateValue;
		const noOptions = !(options && options.length);

		if (currentValue && canCreate && noOptions) {
			const creatableOptions = Array.isArray(currentValue) ? [...currentValue] : [currentValue];
			setState(prevState => ({ ...prevState, creatableOptions }));
		}
	};

	const handleInput = (inputValue, { action, prevInputValue }) => {
		setState(prevState => ({ ...prevState, showValueImage: false }));
		if (!props.isMulti || props.canCreate) return;
		switch (action) {
			case 'input-change':
				return inputValue;
			case 'menu-close':
				return inputValue;
			default:
				return prevInputValue;
		}
	};

	useEffect(() => {
		setInitialCreatableOptions();
		setSelectWidth();
		window.addEventListener('resize', () => setSelectWidth());

		return () => {
			setSelectWidth();
			window.removeEventListener('resize', () => setSelectWidth());
		};
	}, []);

	const { value = null } = props;

	const { creatableOptions, width: containerWidth, value: stateValue } = state;

	const currentValue = 'value' in props ? value : stateValue;

	const isClearable = !props.isMulti && props.canClear !== false;

	const hideMultiValueRemove =
		props.canClear === false && currentValue && currentValue.length === 1;

	const isOptionDisabled = (opt, val) =>
		props.isMulti &&
		props.canClear === false &&
		val.length === 1 &&
		!!val.find(v => v.value === opt.value);

	const selectedIconColor = iconColor || (props.error ? 'statusRed' : 'black');

	/* Custom filterOption function following react-select documentation: https://react-select.com/advanced#custom-filter-logic */
	const filterOption = (candidate, input) => {
		const {
			data: { rawLabel = '' }
		} = candidate;

		const inputRegex = new RegExp(input, 'i');
		/* eslint-disable-next-line no-underscore-dangle */
		return candidate.data.__isNew__ || !!(rawLabel.match(inputRegex) || []).length;
	};
	const formatGroupLabel = data => {
		const { groupType, options } = data;

		const label = translateGroupLabel(groupType);

		return (
			<styles.Badge>
				<span>{label}</span>
				<styles.BadgeCount>{options && options.length}</styles.BadgeCount>
			</styles.Badge>
		);
	};

	const formateCreateLabel = newValue =>
		isDatePicker ? newValue : `${translateHelperString(`common.action.create`)} ${newValue}`;

	const commonProps = {
		components: customizedSelectComponents,
		containerWidth,
		creatableOptions,
		hideMultiValueRemove,
		isClearable,
		isDisabled: disabled,
		isOptionDisabled,
		menuPlacement: 'auto',
		handleCreateOption,
		onChange: handleChange,
		onInputChange: (inputValue, action) => handleInput(inputValue, action),
		onMenuClose: () => setState(prevState => ({ ...prevState, showValueImage: true })),
		formatCreateLabel: newValue => formateCreateLabel(newValue),
		noOptionsMessage: noOptionsMessage || (() => t('views.search.resultsNotFound')),
		showValueImage: state.showValueImage,
		formatGroupLabel: data => formatGroupLabel(data),
		styles,
		...(!props.isAsync && { filterOption })
	};

	return (
		<UseDevicesWrapper>
			{({ onlyDesktop }) => (
				<styles.Container fullWidth={props.fullWidth} ref={ref} rounded={!!props.rounded}>
					{props.icon && (
						<styles.InputIcon
							name={props.icon}
							color={selectedIconColor}
							rounded={!!props.rounded}
						/>
					)}
					{!props.isAsync ? (
						<LocalSelect
							baseProps={props}
							commonProps={{ ...commonProps }}
							closeMenuOnSelect={props.isMulti ? false : props.closeMenuOnSelect}
							onlyDesktop={onlyDesktop}
							options={parseOptions(props.options)}
							ref={selectRef}
							tabSelectsValue={false}
							value={currentValue}
						/>
					) : (
						<AsyncSelect
							cacheOptions
							baseProps={props}
							commonProps={{ ...commonProps }}
							additional={{ page: 1 }}
							closeMenuOnSelect={!props.isMulti}
							debounceTimeout={400}
							defaultOptions={props.preloadOptions}
							onlyDesktop={onlyDesktop}
							formatGroupLabel={formatGroupLabel}
							value={value}
						/>
					)}
					{props.error && <styles.ErrorMessage>{props.errorMessage}</styles.ErrorMessage>}
				</styles.Container>
			)}
		</UseDevicesWrapper>
	);
};

Select.propTypes = {
	/** Si se puede borrar la selección. (False para selección múltiple) */
	canClear: PropTypes.bool,
	/** indica si se puede crear una nueva opcion */
	canCreate: PropTypes.bool,
	/**	Agregar en las opciones las opciones que se crean en el input */
	addCreatedOptions: PropTypes.bool,
	/** Si el menú se cierra al elegir una opción. Setear en false para mantenerlo abierto
	 * (normalmente en casos de selección múltiple) */
	closeMenuOnSelect: PropTypes.bool,
	/**
	 * Si el select esta desabilitado o no
	 */
	disabled: PropTypes.bool,
	/** Si es true, el select indicará un error */
	error: PropTypes.bool,
	/** El mensaje a mostrar del error */
	errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	/** Si es true aplica un width de 100% */
	fullWidth: PropTypes.bool,
	/** Si es true el placeholder se transforma en label flotante */
	hasFloatingLabel: PropTypes.bool,
	name: PropTypes.string,
	/** Ocultar del listado de opciones las que se encuentran seleccionadas */
	hideSelectedOptions: PropTypes.bool,
	/** Nombre del ícono a mostrar al inicio del input */
	icon: PropTypes.string,
	/** Identificador del browse */
	identifier: PropTypes.string,
	/** Si la carga de opciones es asíncrona, en base a esto define la construcción del componente */
	isAsync: PropTypes.bool,
	/** Si acepta varios valores (selección múltiple) */
	isMulti: PropTypes.bool,
	/** Si se pueden buscar opciones */
	isSearchable: PropTypes.bool,
	/** Para la versión asíncrona, debe ser una promise que tiene que devolver como resultado
	 * las opciones correctamente formateadas */
	loadOptions: PropTypes.func,
	/** Ancho máximo de los chips de opciones seleccionadas.
	 * Para poder calcular cuántos entran en el Select */
	maxChipWidth: PropTypes.number,
	/** Mensaje a mostrar cuando no se encontraron resultados en un Select que permite búsquedas */
	noOptionsMessage: PropTypes.func,
	/** Callback disparado cada vez que cambia el valor del select.
	 * En caso de no recibir una función, se enviará el valor al state. */
	onChange: PropTypes.func,
	/** Array de opciones del Select. Puede ser un array de objetos { label, value }, o un array de
	 * strings o números que serán procesados para transformarlos en un objeto con el mismo valor para label y value */
	options: PropTypes.oneOfType([
		PropTypes.arrayOf(optionShape),
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.arrayOf(PropTypes.number)
	]),
	/** Texto a mostrar cuando el Select está vacío. En mobile se reemplaza por la label del Field */
	placeholder: PropTypes.string,
	/** Para la versión asíncrona, permite traer las opciones remotas antes de ser abierto el select remoto */
	preloadOptions: PropTypes.bool,
	/** Array con Objeto(s) { label, value } de la opción seleccionada. Requerido para un controlled component. */
	value: PropTypes.oneOfType([optionShape, PropTypes.arrayOf(optionShape)]),
	/** El ancho del Select, para poder controlar la cantidad de opciones seleccionadas a mostrar
	 * (necesario en casos de selección múltiple).
	 * En caso de no ser un número, se usará el ancho del DOM. */
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
	showImage: PropTypes.bool,
	translateHelperString: PropTypes.func,
	translateGroupLabel: PropTypes.func,
	isDatePicker: PropTypes.bool
};

Select.defaultProps = {
	canClear: true,
	canCreate: false,
	addCreatedOptions: true,
	preloadOptions: false,
	closeMenuOnSelect: true,
	error: false,
	errorMessage: '',
	hasFloatingLabel: true,
	hideSelectedOptions: false,
	isAsync: false,
	isMulti: false,
	isSearchable: true,
	loadOptions: () => null,
	maxChipWidth: 120,
	onChange: () => null,
	options: [],
	placeholder: '',
	width: 'auto',
	disabled: false,
	isDatePicker: false
};

export default translationHOC(Select);
