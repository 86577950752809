import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import UserImage from 'components/UserImage';
import styles from '../styles';
import Checkbox from '../../Checkbox';

const Option = props => {
	const { data, selectProps, isMulti, isSelected, value } = props;
	const { label, userData } = data;
	const { showImage, isIconSelector } = selectProps;

	return (
		<components.Option {...props}>
			{isMulti && <Checkbox checked={isSelected} onChange={() => {}} disabled />}
			{showImage && userData && (
				<styles.ImageWrapper>
					<UserImage {...userData} />
				</styles.ImageWrapper>
			)}
			{isIconSelector && <styles.SelectIcon name={value} />}
			{label}
		</components.Option>
	);
};

Option.propTypes = {
	data: PropTypes.shape({}),
	isMulti: PropTypes.bool,
	isSelected: PropTypes.bool,
	selectProps: PropTypes.shape({}),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Option.defaultProps = {
	data: {},
	selectProps: {}
};

export default Option;
