import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import translationHOC from 'hocs/translationHOC';
import { map } from 'utils/mappers';
import { recoveryPassword } from 'utils/api/user';
import BackToLogin from 'components/AuthResources/BackToLogin';
import Title from 'components/AuthResources/Title';
import styled from 'components/AuthResources/styles';
import { goToURL } from 'utils/location';
import { base64Encode } from 'utils/string';
import { parse } from 'qs';
import { validateURL } from 'utils/url/validateURL';
import Form from './components/Form';
import SuccessMessage from './components/SuccessMessage';

class Content extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			success: false,
			userEmail: '',
			errorMessage: '',
			error: false
		};

		this.submitForm = this.submitForm.bind(this);

		this.toggleLoader = this.toggleLoader.bind(this);
	}

	componentDidMount() {
		this.setPageTitle();
	}

	setPageTitle = () => {
		const { translateHelperString } = this.props;

		const title = `${translateHelperString('common.page.title.forgotPassword')} | JANIS`;

		if (document.title !== title) document.title = title;
	};

	encodeState = () => {
		const { redir } = parse(window.location.search, { ignoreQueryPrefix: true });
		const validURL = validateURL(redir);
		return validURL ? { state: base64Encode(JSON.stringify({ redir: validURL })) } : {};
	};

	submitForm = async formData => {
		this.toggleLoader();

		const { email } = formData;

		try {
			await recoveryPassword({ email, ...this.encodeState() });

			this.setState({
				success: true,
				userEmail: email
			});
		} catch ({ data }) {
			const message = data.message
				? this.props.translateHelperString(data.message, data.messageVariables)
				: map('statusError', data.status);

			this.setState({
				errorMessage: message,
				error: true
			});
		}

		this.toggleLoader();
	};

	toggleLoader = () => {
		this.setState(prevState => ({ isLoading: !prevState.isLoading }));
	};

	render() {
		const { isLoading, success, userEmail, errorMessage, error } = this.state;

		const { translateHelperString } = this.props;

		return (
			<styled.Container>
				<Title
					subtitle={
						!success && `${translateHelperString('login.titles.aRecoveryLinkWillBeSentTo')}:`
					}
				>
					{translateHelperString('login.titles.cannotLogin')}
				</Title>

				{error && <styled.ErrorMessage>{errorMessage}</styled.ErrorMessage>}

				{!success ? (
					<Form onSubmit={this.submitForm} isLoading={isLoading} />
				) : (
					<SuccessMessage email={userEmail} />
				)}

				<BackToLogin action={() => goToURL(`/login${window.location.search}`)} />
			</styled.Container>
		);
	}
}

Content.propTypes = {
	translateHelperString: PropTypes.func
};

export default translationHOC(Content);
