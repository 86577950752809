import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { email, required } from 'utils/redux-form-validation';
import InputField from 'components/AuthResources/InputField';
import styled from 'components/AuthResources/styles';
import Actions from 'components/AuthResources/Actions';

const Form = ({ handleSubmit, isLoading }) => (
	<styled.StyledForm onSubmit={handleSubmit}>
		<styled.FieldsContainer hasSubtitle singleField>
			<Field name="email" type="email" component={InputField} validate={[email, required]} />
		</styled.FieldsContainer>

		<Actions isLoading={isLoading} label="sendRecoveryLink" stickToForm />
	</styled.StyledForm>
);

Form.propTypes = {
	handleSubmit: PropTypes.func,
	isLoading: PropTypes.bool
};

export default reduxForm({ form: 'forgotPassword' })(Form);
