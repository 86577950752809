import styled from 'styled-components';
import { colors, timingFunctions, font, hideOnPrinter } from 'theme';
import Icon from 'components/Icon';
import { mediaBreaks } from 'devices';

const getStatusColor = (state, isBorder = false) => {
	const {
		hasValue,
		isFocused,
		isDisabled,
		selectProps: { error }
	} = state;
	switch (true) {
		case isDisabled:
			return colors.grey;
		case error:
			return colors.statusRed;
		case isFocused:
			return colors.blue;
		case hasValue && !isBorder:
			return colors.darkGrey;
		default:
			return isBorder ? colors.grey : colors.darkGreyPressed;
	}
};

const getDropdownIndicatorColor = state => {
	const {
		isDisabled,
		selectProps: { error }
	} = state;
	switch (true) {
		case isDisabled:
			return colors.grey;
		case error:
			return colors.statusRed;
		default:
			return colors.blue;
	}
};

const getBorderHoverColor = state => {
	const {
		isFocused,
		selectProps: { error }
	} = state;

	switch (true) {
		case error:
			return colors.statusRed;
		case isFocused:
			return colors.blue;
		default:
			return colors.black;
	}
};

const roundedStyles = isRounded =>
	isRounded
		? {
				fontSize: font.base,
				letterSpacing: 0,
				lineHeight: '16px'
		  }
		: {};

export default {
	clearIndicator: base => ({
		...base,
		paddingRight: 2,
		paddingLeft: 4,
		cursor: 'pointer',
		'& svg': {
			fill: colors.black,
			transition: `fill .2s ${timingFunctions.standard}`
		},
		'&:hover svg': {
			fill: colors.blackHover
		}
	}),
	container: (base, state) => {
		const { selectProps = {} } = state;

		return {
			...base,
			width: selectProps.width,
			border: 'none',
			padding: `${selectProps.rounded ? '0 8px' : '0px'}`,
			borderBottom: `${selectProps.rounded ? 'none' : `1px solid ${getStatusColor(state, true)}`}`,
			transition: `border-color 200ms ${timingFunctions.standard}`,
			':hover': {
				borderBottomColor: getBorderHoverColor(state)
			}
		};
	},
	control: base => ({
		...base,
		border: 'none',
		backgroundColor: 'transparent',
		boxShadow: 'none',
		minHeight: 30,
		flexWrap: 'nowrap'
	}),
	dropdownIndicator: (base, state) => {
		const { selectProps = {} } = state;
		return {
			...base,
			padding: selectProps.rounded ? '0' : '0 8px 0 2px',
			cursor: 'pointer',
			'& svg': {
				fill: getDropdownIndicatorColor(state),
				width: 16,
				transition: `transform 150ms ${timingFunctions.standard}`,
				transform: selectProps.menuIsOpen && 'rotate(180deg)'
			}
		};
	},
	indicatorSeparator: () => ({
		display: 'none'
	}),
	input: (base, state) => {
		const { selectProps = {} } = state;
		const { rounded } = selectProps;
		return {
			...base,
			fontWeight: '500',
			fontSize: 14,
			color: colors.black,
			caretColor: colors.blue,
			cursor: 'pointer',
			...roundedStyles(rounded)
		};
	},
	menu: (base, state) => {
		const { selectProps = {} } = state;

		return {
			...base,
			marginTop: selectProps.rounded ? 4 : 1,
			paddingRight: 4,
			left: 0,
			zIndex: 2
		};
	},
	menuList: base => ({
		...base,
		padding: '7px 8px 8px',
		'&::-webkit-scrollbar': {
			width: 4,
			backgroundColor: 'transparent'
		},
		'&::-webkit-scrollbar-track': {
			boxShadow: 'inset 0 0 6px transparent',
			backgroundColor: colors.white,
			margin: 7
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: colors.grey,
			borderRadius: 50
		}
	}),
	noOptionsMessage: () => ({
		color: colors.darkGrey,
		fontSize: 12,
		lineHeight: '16px',
		textAlign: 'left'
	}),
	option: (base, state) => {
		const {
			selectProps: { rounded = false, onlyDesktop }
		} = state;

		return {
			...base,
			fontSize: 13,
			color: state.isSelected ? colors.blue : colors.blackHover,
			transition: `background-color 0.25s ${timingFunctions.standard}`,
			backgroundColor:
				state.isSelected || (state.isFocused && onlyDesktop)
					? colors.lightGreyHover
					: 'transparent',
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'row',
			cursor: 'pointer',
			lineHeight: '18px',
			':hover': {
				backgroundColor: colors.lightGreyHover
			},
			...roundedStyles(rounded)
		};
	},
	placeholder: (base, state) => ({
		...base,
		color: state.isDisabled ? colors.grey : colors.darkGreyPressed,
		display: state.selectProps.hasFloatingLabel && !state.isFocused ? 'none' : 'block',
		fontSize: 14,
		transform: state.hasValue && 'translate(0, -18px) scale(0.75)',
		position: 'absolute',
		top: 'initial'
	}),
	singleValue: (base, state) => {
		const {
			selectProps: { rounded = false, canClear, icon }
		} = state;

		return {
			...base,
			fontWeight: 400,
			fontSize: rounded ? font.base : 14,
			color: state.isDisabled ? colors.grey : colors.black,
			lineHeight: '16px',
			marginLeft: rounded ? '4px' : '0px',
			maxWidth: `calc(100% - ${canClear || icon ? '30px' : '8px'})`
		};
	},
	multiValue: (base, state) => ({
		...base,
		background: colors.lightGreyHover,
		height: 24,
		maxWidth: state.selectProps.maxChipWidth,
		borderRadius: 50,
		flexShrink: 0,
		padding: '0px 5px'
	}),
	multiValueLabel: (base, state) => ({
		...base,
		color: state.isDisabled ? colors.grey : colors.black,
		height: 24,
		fontSize: 13,
		fontWeight: 400,
		paddingLeft: 5,
		lineHeight: '18px'
	}),
	multiValueRemove: (base, props) => {
		const {
			selectProps: { hideMultiValueRemove }
		} = props;

		return {
			...base,
			padding: 0,
			flexShrink: 0,
			background: colors.black,
			color: colors.white,
			borderRadius: 100,
			margin: '4px 0px',
			width: 16,
			height: 16,
			justifyContent: 'center',
			cursor: 'pointer',
			transition: `background .2s ${timingFunctions.standard}`,
			display: hideMultiValueRemove ? 'none' : 'flex',
			':hover': {
				background: colors.blackHover,
				color: colors.white
			}
		};
	},
	valueContainer: (base, state) => {
		const { selectProps = {} } = state;

		const makeWidthValue = ({ icon, isAsync, canClear }) => {
			const width = icon ? 145 : 95;

			const auxLocalWidth = icon || canClear ? 85 : 55;

			const localWidth = icon && canClear ? 85 : auxLocalWidth;

			return isAsync ? width : localWidth;
		};

		const validValue = selectProps?.value;

		const isLarge =
			(selectProps?.rawValue?.label?.length >= 28 && selectProps?.containerWidth > 250) ||
			(!!validValue && typeof validValue !== 'string' && validValue.length);

		const widthValue = selectProps.containerWidth - makeWidthValue(selectProps);
		const validateWidth = Number.isNaN(widthValue) || widthValue < 35 ? 80 : widthValue;

		return {
			...base,
			paddingLeft: 0,
			fontSize: 14,
			overflow: 'visible',
			height: selectProps.rounded ? 30 : 33,
			whiteSpace: 'nowrap',
			cursor: 'pointer',
			'& .select-value-wrapper': {
				maxWidth: validateWidth,
				overflow: 'hidden',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				whiteSpace: 'nowrap',
				fontSize: 11,
				color: state.isDisabled ? colors.grey : colors.black,
				marginLeft: selectProps.icon ? 28 : 0,
				'@media (min-width: 1024px) and (max-width: 1300px)': {
					maxWidth: isLarge ? validateWidth : '8em'
				},
				'@media (min-width: 1300px) and (max-width: 1600px)': {
					maxWidth: isLarge ? validateWidth : '13em'
				}
			},
			'& .select-value-wrapper > div': {
				maxWidth: '100%'
			},
			'& .select-floating-label': {
				fontSize: 14,
				color: getStatusColor(state),
				position: 'absolute',
				left: selectProps.icon ? 30 : 2,
				transformOrigin: 'top left',
				transform:
					state.hasValue && `translate(${selectProps.icon ? '-30px' : '0px'}, -18px) scale(0.75)`,
				transition: `all .2s ${timingFunctions.standard}`,
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				// overflow: 'hidden',
				width: selectProps.icon ? 'calc(100% - 30px)' : '100%'
			},
			'& .select-floating-label--is-focused': {
				color: selectProps.error ? colors.statusRed : colors.blue,
				transform: `translate(${selectProps.icon ? '-30px' : '0px'}, -18px) scale(0.75)`
			}
		};
	},
	Container: styled.div`
		height: 36px;
		width: ${props => (props.fullWidth ? '100%' : props.width)};
		position: relative;
		${props =>
			props.rounded &&
			`
			background-color:${props.backgroundColor ? colors[props.backgroundColor] : colors.white};
			border-radius: 50px;
			border: 1px solid ${colors.grey};
			height: 32px;
		`}
		.select-floating-label,
		svg {
			${hideOnPrinter}
		}
		${mediaBreaks.onlyPrint`
		div {
			border-bottom: none;
		}
		`}
	`,
	InputIcon: styled(Icon)`
		position: absolute;
		flex-shrink: 0;
		flex-grow: 0;
		margin-right: 8px;
		height: 36px;
		${props =>
			props.rounded &&
			`
		margin-left: 8px;
		padding-bottom: 7px;
		`}
	`,
	ErrorMessage: styled.span`
		color: ${colors.statusRed};
		font-size: 12px;
		line-height: 14px;
		display: block;
		transform: translateY(2px);
		word-break: break-word;
	`,
	ComponentWrapperDoc: styled.div`
		height: 70px;
	`,
	SelectIcon: styled(Icon)`
		margin-right: 8px;
	`,
	ImageWrapper: styled.div`
		margin-right: 8px;
	`,
	BadgeCount: styled.span`
		border-radius: 30px;
		color: ${colors.blackPressed};
		display: inline-block;
		font-size: ${font.small};
		font-weight: normal;
		line-height: 1;
		min-width: 1;
		padding: 6px 8px;
		text-align: center;
		background-color: ${colors.lightGrey};
	`,
	Badge: styled.div`
		display: flex;
		align-items: center;
		justify-content: space-between;
	`,
	RowContainer: styled.div`
		display: flex;
		align-items: center;
		width: 100%;
	`
};
