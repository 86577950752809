import React from 'react';
import translationHOC from 'hocs/translationHOC';
import styled from './styles';

const InputField = ({ input, meta, translateHelperString, ...props }) => (
	<styled.FieldWrapper>
		<styled.InputWrapper>
			<styled.Input
				{...input}
				fullWidth
				autoComplete
				id={input.name}
				type={props.type}
				isFocused={!meta.error && meta.dirty && meta.active}
				error={meta.touched && meta.invalid}
				errorMessage={translateHelperString(meta.error)}
				label={translateHelperString(`login.field.${input.name}`)}
			/>
		</styled.InputWrapper>
	</styled.FieldWrapper>
);

export default translationHOC(InputField);
