import { get, uniq, set, flatten } from 'lodash';
import { callRequest } from 'utils/request';
import { START_ASYNC_LOADING, STOP_ASYNC_LOADING, SET_ASYNC_ERROR } from '../types';
import { getCurrentBrowse } from './actions-utils';
import { fetchSuccessWithoutTotal } from './fetch';

const startAsyncLoading = name => currentBrowse => ({
	type: START_ASYNC_LOADING,
	meta: { name: currentBrowse },
	name
});

const stopAsyncLoading = name => currentBrowse => ({
	type: STOP_ASYNC_LOADING,
	meta: { name: currentBrowse },
	name
});

const setAsyncError = name => currentBrowse => ({
	type: SET_ASYNC_ERROR,
	meta: { name: currentBrowse },
	name
});

/**
 * function  makes a request for each asynchronous field
 * @param {Object} field
 * @returns {<Promise>}
 */
const sourceRequest = field => {
	const { source = {}, dataMatching = {} } = field;

	if (!field.sourceFieldsIds.length) return [];

	const filters = { filters: { [dataMatching.remote || 'id']: field.sourceFieldsIds } };

	return callRequest(source, filters);
};

/**
 * function that returns fields with asynchronous data
 * @param {Object} field
 * @returns {<Promise>}
 */
const setRowAsyncData = field => currentBrowse => async (dispatch, getState) => {
	const { dataMapping = {}, name, dataMatching, targetField } = field;

	const getBrowseData = () => getCurrentBrowse(getState(), currentBrowse);

	const isAsyncList = dataMatching && targetField;

	try {
		const response = await sourceRequest(field);

		const browse = getBrowseData();

		if (!browse) return null;

		const { rows } = browse;

		const toString = val => (val === null || val === undefined ? val : val.toString());

		const formattedData = rows.map(row => {
			const currentName = isAsyncList ? dataMatching.local : name;

			const fieldValue = get(row, currentName);

			if (!isAsyncList && fieldValue) {
				const res = response.find(data => toString(data.id) === toString(fieldValue));

				const formattedRow = { ...row };

				Object.keys(dataMapping).forEach(key => {
					set(formattedRow, dataMapping[key], res ? get(res, key) : null);
				});

				return formattedRow;
			}

			if (isAsyncList && fieldValue) {
				const fieldValues = Array.isArray(fieldValue) ? fieldValue : [fieldValue];

				const res = flatten(
					fieldValues.map(value =>
						response.filter(data => toString(get(data, dataMatching.remote)) === toString(value))
					)
				);

				const formattedRow = { ...row };

				set(formattedRow, targetField, res);

				return formattedRow;
			}

			return row;
		});

		return formattedData;
	} catch (error) {
		const browse = getBrowseData();

		if (!browse) return null;

		throw error;
	}
};

/**
 * function that returns asynchronous fields
 * @param {Array} fields
 * @param {Array} rows
 * @returns {Array}
 */
const getAsyncFieldData = (fields, rows) => {
	const asyncFields = [];

	const findAsyncFields = (field, arrayReference) => {
		if (field.component === 'AsyncWrapper' || field.component === 'AsyncUserChip') {
			arrayReference.push(field);
		}

		if (field.component === 'FieldList') {
			field.componentAttributes.fields.forEach(innerField =>
				findAsyncFields(innerField, arrayReference)
			);
		}
	};

	fields.forEach(field => {
		findAsyncFields(field, asyncFields);
	});

	return asyncFields.map(field => {
		let { componentAttributes = {} } = field;
		const { dataMatching = {}, source } = componentAttributes;
		const { name } = field;

		// get the necessary ids to filter the request

		if (field.component === 'AsyncUserChip') {
			componentAttributes = {
				dataMapping: {
					firstname: `${name}Data.firstName`,
					lastname: `${name}Data.lastName`,
					email: `${name}Data.email`,
					image: `${name}Data.image`,
					status: `${name}Data.status`
				},
				source: source || {
					service: 'id',
					namespace: 'public-user',
					method: 'list'
				}
			};
		}

		const sourceFieldsIds = rows.map(row => get(row, dataMatching.local || name)).filter(Boolean);

		return {
			...componentAttributes,
			name,
			sourceFieldsIds: flatten(uniq(sourceFieldsIds))
		};
	});
};

export const startAsyncFieldsLoading = () => currentBrowse => async (dispatch, getState) => {
	const state = getCurrentBrowse(getState(), currentBrowse);

	const { rows = [], schema = {} } = state;

	// get all async fields
	const asyncFields = getAsyncFieldData(schema.fields, rows);

	asyncFields.forEach(({ name }) => {
		dispatch(startAsyncLoading(name)(currentBrowse));
	});
};
/**
 *	function that asynchronously formats the necessary data
 */
export const processAsyncFields = () => currentBrowse => (dispatch, getState) => {
	const state = getCurrentBrowse(getState(), currentBrowse);

	const { rows, page, schema = {} } = state;

	// get all async fields
	const asyncFields = getAsyncFieldData(schema.fields, rows);

	const setAsyncData = async field => {
		const { name } = field;

		try {
			// Add the data obtained in the request to each field
			const formattedData = await dispatch(setRowAsyncData(field)(currentBrowse));
			if (formattedData === null) return;

			dispatch(fetchSuccessWithoutTotal({ rows: formattedData, page })(currentBrowse));

			dispatch(stopAsyncLoading(name)(currentBrowse));
		} catch (error) {
			if (error === null) return;

			dispatch(setAsyncError(name)(currentBrowse));
		}
	};

	asyncFields.forEach(setAsyncData);
};
