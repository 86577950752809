import { get, flatten } from 'lodash';
import ConditionalFunctions from './conditional-functions';

const getDynamicValue = dynamicValue => {
	const options = {
		date: { formattedDynamicValue: new Date().toISOString() }
	};
	return options[dynamicValue];
};

/**
 * Process the conditions and return the results
 * @param {array} andConditionals - conditions for precess
 * @param {object} data - Object when take values for compare
 * @param {string} prefix - prefix for FieldsArray internal fields
 */
export const processConditionals = ({ andConditionals, data, prefix, matchFieldName }) => {
	const getFieldsArrayFieldName = name =>
		name.includes('rootData.') ? name.replace('rootData.', '') : `${prefix}.${name}`;
	const andConditionalsResults = andConditionals.map(andCondition => {
		const orConditionalsResults = andCondition.map(orCondition => {
			const {
				field,
				name,
				referenceValue,
				dynamicValue,
				referenceValueType,
				innerField = []
			} = orCondition;

			const innerFields = Array.isArray(innerField) ? innerField : [innerField];

			const multipleFields = Array.isArray(field);

			const matchField =
				prefix && matchFieldName ? matchFieldName.replace(`${prefix}.`, '') : matchFieldName;

			let fieldsNames = field || matchField;

			if (prefix)
				fieldsNames = multipleFields
					? fieldsNames.map(getFieldsArrayFieldName)
					: getFieldsArrayFieldName(fieldsNames);

			let values = get(data, fieldsNames);

			let refValue = referenceValue;

			if (dynamicValue) {
				const { formattedDynamicValue } = getDynamicValue(dynamicValue);
				refValue = formattedDynamicValue;
			}

			if (multipleFields) {
				values = {};

				fieldsNames.forEach(paramName => {
					values[paramName] = get(data, paramName);
				});
			}

			if (referenceValueType === 'dynamic') {
				if (prefix) refValue = getFieldsArrayFieldName(refValue);

				refValue = get(data, refValue);
			}

			if (innerFields.length) {
				const getValue = (fieldName, key) => {
					const currentValue = multipleFields ? values[key] : values;

					const innerValue = Array.isArray(currentValue)
						? flatten(currentValue.map(value => get(value, fieldName)))
						: get(currentValue, fieldName);

					if (multipleFields) values[key] = innerValue;
					else values = innerValue;
				};

				if (multipleFields) {
					Object.keys(values).forEach(key =>
						innerFields.forEach(fieldName => getValue(fieldName, key))
					);

					return Object.values(values).every(value =>
						Array.isArray(value)
							? value.some(val => ConditionalFunctions[name](val, false, refValue))
							: ConditionalFunctions[name](value, false, refValue)
					);
				}

				innerFields.forEach(getValue);

				if (Array.isArray(values))
					return values.some(value => ConditionalFunctions[name](value, false, refValue));
			}

			if (!values && data && Array.isArray(data)) {
				const itemData = data.find(item => item.name === fieldsNames);
				values = itemData && itemData.data;
			}

			const conditionResult = ConditionalFunctions[name](values, multipleFields, refValue);

			return conditionResult;
		});

		return orConditionalsResults.some(result => result);
	});

	return andConditionalsResults.every(result => result);
};

/**
 * Filters the components and returns only the ones that should be shown according to their showWhen conditions
 * @param {array} components
 * @param {object} data
 */
export const componentsToShow = (components, data) =>
	components.filter(component => {
		const { conditions = {} } = component;
		const { showWhen } = conditions;
		return conditions && showWhen
			? !!processConditionals({ andConditionals: showWhen, data, matchFieldName: component.name })
			: true;
	});
