import { isString } from 'lodash';
import { useEffect, useState } from 'react';

const usePopup = time => {
	const [showPopup, setShowPopup] = useState(false);
	const isStringValue = isString(time);

	const autoClosePopup = (number, setState) => {
		const seconds = (number + 1) * 1000;
		setTimeout(() => {
			setState(false);
		}, seconds);
	};

	const closePopup = () => setShowPopup(false);

	useEffect(() => {
		if (time === 0 || isStringValue) setShowPopup(true);
		if (time > 0) {
			setShowPopup(true);
			autoClosePopup(time, setShowPopup);
		}
	}, []);

	return { showPopup, setShowPopup, closePopup };
};

export default usePopup;
